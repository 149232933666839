<template>
  <div class="setting-tab">
    <div class="ml-3">
      <!-- Currency and Language settings -->
      <div>
        <h4 class="mb-2">Company Settings:</h4>
        <div class="d-flex mb-2">
          <div class="col-3 pl-0">
            <h5 class="font-weight-normal">Company Currency</h5>
            <v-select
              v-model="company_settings.currency"
              :options="['USD', 'JPY', 'EUR', 'GBP']"
              :clearable="false"
              placeholder="Select company currency"
              :disabled="!isWeekend"
            />
          </div>

          <div class="col-3 pl-0">
            <h5 class="font-weight-normal">Company Language</h5>
            <v-select
              v-model="company_settings.language"
              :options="available_languages"
              :reduce="lang => lang.value"
              label="text"
              :clearable="false"
              placeholder="Select company language"
            />
          </div>

          <div class="d-flex align-items-end">
            <b-button variant="primary" @click="edit_company_settings()" :disabled="!isLoaded">
              Save Changes
            </b-button>
          </div>
        </div>
        <p class="company-settings__note text-heading-color">Note: Company currency can be changed only from 00:00 on Saturday until 11:59 on Sunday</p>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "@axios";
import { notifyError, notifySuccess } from '@/helper/notifications'
// validation
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  data() {
    return {
      company_settings: {
        currency: "",
        language: "",
      },
      available_languages: [{ text: "English", value: "en" }],
      editing_item: null,
      required,
      isLoaded: false
    };
  },

  mounted() {
    this.init()
  },

  methods: {
    ...mapActions({
      fetchSettings: 'companySettings/fetchSettings',
      updateSettings: 'companySettings/updateSettings'
    }),
    init() {
      this.isLoaded = false
      this.fetchSettings(['risk_management']).then(({ data }) => {
        if (data.settings) {
          this.company_settings.currency = data.settings.currency;
          this.company_settings.language = data.settings.language;
          this.isLoaded = true
        }
      })
    },
    edit_company_settings() {

      if (!this.company_settings.currency || !this.company_settings.language) return

      if (this.company_settings.currency !== this.companyCurrency && !this.isWeekend) return

      this.isLoaded = false
      this.updateSettings({ path: ['risk_management'], data: this.company_settings }).then(({ data }) => {
        this.company_settings = data.settings
        this.init() // has to fetch again to update vuex riskMangementSettings because SET_SETTINGS doesn't update vuex riskManagementSettings because PATCH risk_management is implemented diffrent
        notifySuccess('Success!', 'Company settings edited')
      }).catch(err => {
        notifyError("Error!", err?.response?.data?.errors?.message ?? err)
      })
    },

  },

  computed: {
		...mapGetters({
			timezoneOffset: 'companySettings/timezoneOffset'
		}),
    isWeekend() {
      let serverDatetime = moment.utc().utcOffset(this.timezoneOffset).format()
      let day = moment.parseZone(serverDatetime).day()
      return (day >= 5) // 0..6 Monday..Sunday
    },
    companyCurrency() {
      const riskMangementSettings = this.$store.getters['companySettings/settingsByPath'](['risk_management'])
      if (riskMangementSettings.settings)
        return riskMangementSettings.settings.currency
      return ''
    },
  }
};
</script>

<style scoped>
</style>