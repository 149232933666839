var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',{staticClass:"w-100 py-5 text-center",staticStyle:{"border":"none !important"}},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_c('div',{class:("common-table table-" + _vm.maxDisplayRows + "-rows " + _vm.overflowClass + " w-100 table-header-sm")},[_c('div',{ref:"table-outer-1",staticClass:"table-outer-1 w-100"},[_c('div',{ref:"table-outer-2",staticClass:"table-outer-2 w-100",style:({
				paddingRight: (_vm.tableItems.length > _vm.maxDisplayRows) ? '0px' : '0px',
				marginRight: (_vm.tableItems.length > _vm.maxDisplayRows) ? '-7px' : '0px'
			}),on:{"scroll":_vm.scrollContentHandler}},[_c('b-table-simple',{staticClass:"w-100",attrs:{"small":_vm.small,"hover":_vm.hover,"no-border-collapse":_vm.noBorderCollapse,"borderless":"","striped":_vm.striped,"fixed":""}},[_c('b-thead',{style:({
						'overflow-y': _vm.tableItems.length + 1 < _vm.maxDisplayRows ? 'auto !important' : 'scroll !important'
					})},[_c('b-tr',{ref:"header-1st-row"},[_vm._t("header",_vm._l((_vm.computedFields),function(field,index){return _c('b-th',{key:index,staticClass:"font-weight-bolder",class:field.sortField ? 'cursor-pointer field.thClass' : 'field.thClass',style:(Object.assign({}, {'padding': '1rem'},	// 100px is to large for some column
										field.thStyle)),on:{"click":function($event){return _vm.sortField(field)}}},[_vm._t(("head(" + (field.key) + ")"),[_vm._v(" "+_vm._s(field.label)+" ")],{"field":field,"value":field.label})],2)}))],2)],1),_c('b-tbody',_vm._l((_vm.tableItems),function(item,index){return _c('b-tr',{key:index},_vm._l((_vm.computedFields),function(field,jj){return _c('b-td',{key:jj,ref:("cell-" + index + "-" + jj),refInFor:true,staticClass:"py-0",class:{ 'row-selected': _vm.selectedRow === index },on:{"click":function($event){return _vm.selectRow(index)}}},[(jj == item.level)?_c('span',[(jj < _vm.computedFields.length - 1)?_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":['fad', 'folder-open']}}):_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":['fal', 'layer-group']}}),_c('span',[_vm._v(" "+_vm._s(item.text)+" ")]),(jj < _vm.computedFields.length - 1 && item.opened)?_c('font-awesome-icon',{staticClass:"btn-icon",attrs:{"icon":['fas', 'caret-down']},on:{"click":function($event){return _vm.collapseItem(index)}}}):_vm._e(),(jj < _vm.computedFields.length - 1 && !item.opened)?_c('font-awesome-icon',{staticClass:"btn-icon",attrs:{"icon":['fas', 'caret-right']},on:{"click":function($event){return _vm.collapseItem(index)}}}):_vm._e()],1):_vm._e(),(jj <= item.level - 1)?_c('div',{staticClass:"tree-branch"},[(jj == item.level - 1)?_c('div',{staticClass:"horizontal-line"}):_vm._e(),_c('div',{staticClass:"vertical-line",class:{ 
											'full': !item.isLastChild[item.level - jj - 1], 
											'half': item.isLastChild[item.level - jj - 1], 
											'none': item.isLastChild[item.level - jj - 1] && index > 0 && _vm.tableItems[index - 1].isLastChild[_vm.tableItems[index - 1].level - jj - 1], 
										}})]):_vm._e()])}),1)}),1)],1)],1)]),_c('div',{ref:"anchor",staticStyle:{"left":"0","position":"absolute","top":"0"}}),(_vm.isMounted  && !_vm.isLoading && _vm.tableItems.length > _vm.maxDisplayRows)?_c('div',{ref:"scrollbar-anchor",staticClass:"scrollbar-anchor",style:({
			top: 0,
			right: '0px',
			height: (_vm.tableOuterHeight + "px")
		})},[_c('div',{ref:"track",staticClass:"track",style:({
				height: _vm.trackHeight + 'px',
				top: _vm.tableHeaderHeight + 'px'
			})},[_c('div',{ref:"thumb",staticClass:"thumb",style:({
					height: _vm.thumbHeight + 'px',
					top: _vm.thumbTopPosition + 'px'
				}),on:{"mousedown":_vm.mouseDownThumbHandler}})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }