
<template>
	<b-container fluid id="settings">
		<b-row>
			<b-col class="pr-0 pl-0">
				<b-tabs vertical nav-wrapper-class="nav-vertical pl-0 custom-vertical-nav" active-nav-item-class="text-left font-weight-bolder" nav-item-class="custom-vertical-nav-padding">
					<b-tab @click="$router.push('group-management')" :active="routeName === 'group-management'" title-link-class="justify-content-start" >
						<template #title>
								<font-awesome-icon :icon="['far', 'layer-group']" class="font-medium-3" />
								Group Management
						</template>
						<GroupManagement v-if="routeName === 'group-management'" />
					</b-tab>
      
					<b-tab @click="$router.push('risk-parameters')" :active="routeName === 'risk-parameters'" title-link-class="justify-content-start">
						<template #title>
								<font-awesome-icon :icon="['far', 'sliders-h']" class="font-medium-3"/>
								Risk Parameters
						</template>
						<RiskParameters v-if="routeName === 'risk-parameters'" class="pt-50"/>
					</b-tab>

					<b-tab @click="$router.push('company-settings')" :active="routeName === 'company-settings'" title-link-class="justify-content-start">
						<template #title>
								<font-awesome-icon :icon="['far', 'cogs']" class="font-medium-3"/>
								Company Settings
						</template>
						<CompanySettings v-if="routeName === 'company-settings'" class="pt-50"/>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import GroupManagement from './GroupManagement'
import RiskParameters from './RiskParameters'
import CompanySettings from './CompanySettings'

export default {
	components: {
		GroupManagement,
		RiskParameters,
		CompanySettings,
	},
	data() {
		return {}
	},
	methods: {

	},
	computed: {
		routeName() {
			return this.$route.path.split('/')[2]
		}
	},
	mounted() {
	}
}
</script>
<style>
.custom-vertical-nav > ul > li > a {
	padding: 16px!important;
	padding-top: 7px!important;
}
.custom-vertical-nav > ul > li {
	padding-bottom: 7px!important;
}
</style>