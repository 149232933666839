<template>
    <b-tabs class="ml-3" active-nav-item-class="font-weight-bolder" nav-class="font-small-4">
        <b-tab title="Platform group" title-link-class="px-0 mr-2">
            <platform-group
                :isLoading="isLoading"
                :group_levels="group_levels"
                :group_organization="group_organization"
                :parentOptions="calcParentOptions"
                @setGroupOrganization="group_organization = $event"
                @fetch_groups="fetch_groups"
                @sync-group="$bvModal.show('sync_group_modal')"
            />
        </b-tab>

        <b-tab title="Group Organization" title-link-class="px-0">
            <group-levels
                :isFetchingGroupLevels="isFetchingGroupLevels"
                :group_levels="group_levels"
                :isEditingGroupLevel="isEditingGroupLevel"
                @editGroupLevel="$set(isEditingGroupLevel, $event, true)"
                @renameGroupLevel="renameGroupLevel"
                @addGroupLevel="addGroupLevel"
                @deleteLastGroupLevel="deleteLastGroupLevel"
            />

            <!-- Group Organization -->
            <div>
                <h4 class="mb-1 font-small-3">Group Organization</h4>
                <b-row>
                    <b-col>
                        <b-card no-body class="px-50">
                            <GroupOrganizationTable
                                :levels="tree_levels"
                                :items="tree_view"
                                :isLoading="isLoading"
                                @row-selected="setGroupOrganizationSelectedRow"
                                @collapse="collapseItem"
                            />
                            <div class="p-1">
                                <b-button
                                    variant="success"
                                    size="sm"
                                    class=""
                                    @click="handleGroupOrganizationAction('NEW_GROUP_FOLDER')"
                                    >+ New Group Folder</b-button
                                >
                                <b-button
                                    variant="success"
                                    size="sm"
                                    class="ml-1"
                                    @click="handleGroupOrganizationAction('NEW_SUBFOLDER')"
                                    >+ New Subfolder</b-button
                                >
                                <b-button
                                    variant="success"
                                    size="sm"
                                    class="ml-1"
                                    @click="handleGroupOrganizationAction('RENAME')"
                                    >Rename Folder</b-button
                                >
                                <b-button
                                    variant="danger"
                                    size="sm"
                                    class="ml-1"
                                    @click="handleGroupOrganizationAction('DELETE_FOLDER')"
                                    >- Delete Folder</b-button
                                >
                                <small class="text-danger ml-1" v-if="actionOnRowError">{{ actionOnRowError }}</small>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
        </b-tab>

        <!-- Group Organization Modal -->
        <b-modal
            id="modal-new-folder"
            hide-footer
            no-close-on-backdrop
            centered
            size="sm"
            title="New Group Folder"
            ref="my-modal"
        >
            <validation-observer ref="modalNewGroupFolder">
                <div class="d-flex w-100">
                    <div class="mr-2 mt-1">
                        Folder Name
                        <validation-provider #default="{ errors }" name="Folder Name" rules="required">
                            <b-form-input
                                class="mt-2 w-100"
                                v-model="group_folder_item.name"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger mt-50">{{ errors[0] }}</small>
                        </validation-provider>
                    </div>
                </div>
            </validation-observer>
            <b-button
                variant="gradient-success"
                class="custom-btn mt-3 float-right"
                @click="createNewGroupFolder(false)"
                >Save</b-button
            >
        </b-modal>

        <!-- New Organization Folder Modal -->
        <b-modal id="modal-new-subfolder" hide-footer no-close-on-backdrop centered size="sm" title="New Subfolder">
            <validation-observer ref="modalNewSubfolder">
                <div class="d-flex w-100">
                    <div class="mr-2 mt-1">
                        Folder Name
                        <validation-provider #default="{ errors }" name="Folder Name" rules="required">
                            <b-form-input
                                class="mt-2 w-100"
                                v-model="group_folder_item.name"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger mt-50">{{ errors[0] }}</small>
                        </validation-provider>
                    </div>
                </div>
            </validation-observer>
            <b-button variant="gradient-success" class="custom-btn mt-3 float-right" @click="createNewGroupFolder(true)"
                >Save</b-button
            >
        </b-modal>

        <!-- Rename Organization Folder Modal -->
        <b-modal
            id="modal-rename-folder"
            hide-footer
            no-close-on-backdrop
            centered
            size="sm"
            title="Rename Folder"
            v-if="groupOrganizationSelectedItem"
        >
            <div class="mt-1">
                Old Name
                <b-form-input class="mt-2" readonly :value="groupOrganizationSelectedItem.text"></b-form-input>
            </div>
            <validation-observer ref="modalNewSubfolder">
                <div class="d-flex w-100">
                    <div class="mt-1 w-100">
                        New Name
                        <validation-provider #default="{ errors }" name="Folder Name" rules="required">
                            <b-form-input
                                class="mt-2 w-100"
                                v-model="group_folder_item.name"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger mt-50">{{ errors[0] }}</small>
                        </validation-provider>
                    </div>
                </div>
            </validation-observer>
            <b-button variant="gradient-success" class="custom-btn mt-3 float-right" @click="renameFolder"
                >Save</b-button
            >
        </b-modal>

        <!-- Delete Organization Folder Modal -->
        <b-modal id="modal-delete-folder" hide-footer centered title="Delete Folders">
            <div class="py-3 text-center">
                Are you sure to delete the group:
                {{ groupOrganizationSelectedItem ? groupOrganizationSelectedItem.text : "" }}?
            </div>
            <b-overlay class="text-center mb-3" :show="isLoading" rounded="sm" />
            <div class="m-auto text-center" v-if="!isLoading">
                <b-button variant="gradient-success" class="custom-btn" @click="deleteFolder">Confirm</b-button>
                <b-button class="ml-2 custom-btn" @click="$bvModal.hide('modal-delete-folder')">Cancel</b-button>
            </div>
        </b-modal>

        <!-- Delete Group Warning Modal -->
        <b-modal id="delete_group_modal" hide-footer centered title="Delete Group Folder">
            <div class="py-3 text-center">
                Are you sure to delete the group: {{ item_delete ? item_delete.settings.name : "" }}?
            </div>
            <b-overlay class="text-center mb-3" :show="isLoading" rounded="sm" />
            <div class="m-auto text-center" v-if="!isLoading">
                <b-button variant="gradient-success" class="custom-btn" @click="deleteGroup">Confirm</b-button>
                <b-button class="ml-2 custom-btn" @click="$bvModal.hide('delete_group_modal')">Cancel</b-button>
            </div>
        </b-modal>

        <!-- Synchronization Platform Group Warning Modal -->
        <b-modal id="sync_group_modal" hide-footer centered title="Synchronize The Platform Groups">
            <div class="py-3 text-center">
                Are you sure to synchronize the platform groups?
            </div>

            <b-overlay class="text-center mb-3" :show="isLoading" rounded="sm" />
            <div class="m-auto text-center" v-if="!isLoading">
                <b-button variant="gradient-success" class="custom-add-new-btn" @click="syncGroup">Confirm</b-button>
                <b-button class="ml-2 custom-add-new-btn" @click="$bvModal.hide('sync_group_modal')">Cancel</b-button>
            </div>
        </b-modal>
    </b-tabs>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import VJstree from "vue-jstree";
import axios from "@axios";
import VueSelect from "../forms/form-element/vue-select/VueSelect.vue";
import CommonTable from "@/components/tables/CommonTable.vue";
import GroupOrganizationTable from "@/components/tables/GroupOrganizationtTable.vue";
// validation
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { notifyError, notifySuccess } from "@/helper/notifications";

import groupManagementFields from "./groupManagementFields.js";
import PlatformGroup from "./PlatformGroup.vue";
import GroupLevels from "./GroupLevels.vue";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        vSelect,
        VJstree,
        ToastificationContent,
        VueSelect,
        CommonTable,
        GroupOrganizationTable,
        PlatformGroup,
        GroupLevels,
    },

    data() {
        return {
            isLoading: true,
            required,
            group_levels: [],

            group_organization: {},
            group_folder_item: {
                name: null,
                level: null,
            },
            // filter for get exactly group level
            filter: [],
            site_settings_path: "site_settings/risk_management/group_organization/",

            platform_groups: [],

            teams: [],

            region_tree: [],

            // add, edit platform Group
            isLoading: false,
            isEditGroup: false,
            origin_path_onEdit: "",
            server_name_options: {},
            platform_group_item: {
                name: "",
                path: "",
                created: "",
                currency: "",
                server_name: "",
                platform_group: "",
                short_path: "",
                created: new Date().toLocaleDateString("en-US"),
            },
            form_select: {
                platform_group_parent: "",
            },
            item_delete: null,
            groupOrganizationSelectedItem: null,
            actionOnRowError: "",

            isFetchingGroupLevels: true,
            isEditingGroupLevel: [],
            groupLevelEditingSteps: [],
        };
    },

    methods: {
        startEditingGroupLevels() {
            this.groupLevelEditingSteps = [];
            this.group_levels.forEach((level, index) => {
                this.$set(this.isEditingGroupLevel, index, false);
            });
        },
        addGroupLevel() {
            // # params = {update_actions: [{
            // #   action_type: "add/remove/rename",
            // #   level: 0/1/...,
            // #   name:
            // # }, ...]
            // # }
            this.groupLevelEditingSteps.push({
                action_type: "add",
                level: this.group_levels.length,
                name: `Level ${this.group_levels.length + 1}`,
            });
            this.saveGroupLevels();
        },
        deleteLastGroupLevel() {
            this.groupLevelEditingSteps.push({
                action_type: "remove",
                level: this.group_levels.length - 1,
                name: this.group_levels[this.group_levels.length - 1],
            });
            this.saveGroupLevels();
        },
        renameGroupLevel(index, new_name) {
            this.$set(this.isEditingGroupLevel, index, false);
            if (new_name != this.group_levels[index]) {
                this.groupLevelEditingSteps.push({
                    action_type: "rename",
                    level: index,
                    name: new_name,
                });
                this.saveGroupLevels();
            }
        },
        async saveGroupLevels() {
            try {
                this.isFetchingGroupLevels = true;
                // console.log('steps', this.groupLevelEditingSteps)
                let res = await axios.patch(`${process.env.VUE_APP_API_URL}/site_settings/update_risk_group_levels`, {
                    update_actions: this.groupLevelEditingSteps,
                });
                // console.log(res)
                this.group_levels = res.data.settings;
                this.fetch_groups();
                this.fetch_trading_server();
                notifySuccess("Successful", "Save group levels successfully!");
            } catch (err) {
                console.error(err);
                notifyError("Error", err?.response?.data?.errors?.message ?? "Save group levels failed");
            } finally {
                this.isEditingGroupLevels = false;
                this.groupLevelEditingSteps = [];
                this.isFetchingGroupLevels = false;
            }
        },
        syncGroup() {
            this.isLoading = true;
            axios
                .get(`${process.env.VUE_APP_API_URL}platforms/sync_all_server_groups`)
                .then((res) => {
                    this.$bvModal.hide("sync_group_modal");
                    this.isLoading = false;
                    notifySuccess("Successful", "Synchronize successfully!");
                    this.fetch_groups();
                })
                .catch((err) => {
                    this.isLoading = false;
                    notifyError("Error", err?.response?.data?.errors?.message ?? "Synchronization failed");
                });
        },

        treeItemClick(node) {},

        async deleteFolder() {
            try {
                // console.log('tree', this.region_tree)
                this.loading = true;
                let url = `remove_risk_group_folder/risk_management${this.groupOrganizationSelectedItem.path}`;
                let res = await axios.delete(`${process.env.VUE_APP_API_URL}site_settings/${url}`);
                this.loading = false;
                this.fetch_groups();
                notifySuccess("Successful", "Delete successfully!");
            } catch (error) {
                console.error(error);
                notifyError(
                    "Error",
                    err?.response?.data?.errors?.message ?? "Cannot delete the folder. Try again later!"
                );
            } finally {
                this.$bvModal.hide("modal-delete-folder");
                this.loading = false;
            }
        },

        async renameFolder() {
            if (this.group_folder_item.name == "") return;
            let setting = {
                name: this.group_folder_item.name,
            };

            let path = "/site_settings/risk_management/" + this.groupOrganizationSelectedItem.path;

            try {
                this.isLoading = true;
                this.$bvModal.hide("modal-rename-folder");
                let res = await axios.patch(`${process.env.VUE_APP_API_URL}${path}`, {
                    setting,
                });
                this.isLoading = false;
                this.fetch_groups();
                notifySuccess("Successful", "Rename successfully!");
            } catch (err) {
                console.error(err);
                notifyError(
                    "Error",
                    err?.response?.data?.errors?.message ?? "Cannot rename the folder. Try again later!"
                );
            }
        },
        openDeleteWarningModal(item) {
            this.item_delete = item;
            this.$bvModal.show("delete_group_modal");
        },

        deleteGroup() {
            this.isLoading = true;
            axios
                .delete(
                    `${process.env.VUE_APP_API_URL}site_settings/destroy_node/risk_management${this.item_delete.settings.path}/${this.item_delete.short_name}`,
                    {
                        params: {
                            delete_relate_metric_data: 1,
                            pass: 1,
                        },
                    }
                )
                .then((res) => {
                    this.$bvModal.hide("delete_group_modal");
                    this.isLoading = false;
                    notifySuccess("Successful", "Delete successfully!");
                    this.fetch_groups();
                })
                .catch((err) => {
                    this.isLoading = false;
                    notifyError("Error", err?.response?.data?.errors?.message ?? "Delete folder failed");
                });
        },

        openEditGroupModal(item) {
            this.isEditGroup = true;
            this.form_select.platform_group_parent = item.parent_group;
            this.origin_path_onEdit = item.path;
            this.platform_group_item = {
                name: item.settings.name,
                path: item.path,
                currency: item.settings.currency,
                server_name: item.settings.server_name,
                platform_group: item.settings.platform_group,
                short_name: item.short_name,
                created: item.settings.created,
            };
            this.$bvModal.show("new-group-modal");
        },

        fetch_trading_server() {
            axios
                // .get(`${process.env.VUE_APP_API_URL}public_site_settings/trading_platforms?recursive`)
                //? mockData
                .get("/TradingPLatforms/data")
                .then((res) => {
                    Object.keys(res.data.children).forEach((key) => {
                        console.log(462, res.data);
                        if (key != "default_platform")
                            // this.$set(this.server_name_options, key, res.data.children[key].children.groups.settings);

                            //mockData
                            this.$set(this.server_name_options, key, res.data.settings);
                    });
                    // console.log(this.server_name_options)
                });
        },

        after_save_group() {
            this.emptyPlatformGroup();
            this.$bvModal.hide("new-group-modal");
            this.form_select.platform_group_parent = "";
            this.fetch_groups();
            notifySuccess("Successfully", "Save successfully!");
        },

        emptyPlatformGroup() {
            this.isEditGroup = false;
            this.form_select.platform_group_parent = "";
            this.platform_group_item = {
                name: "",
                path: "",
                created: "",
                currency: "",
                server_name: "",
                platform_group: "",
                short_path: "",
                created: new Date().toLocaleDateString("en-US"),
            };
        },

        getChildren(sitesettings, depth, current_path = "", short_path = "") {
            let x = current_path + "/" + sitesettings.short_name;

            let s_path =
                sitesettings.short_name != "group_organization"
                    ? short_path + "/" + sitesettings.short_name
                    : sitesettings.short_name;

            var result = [];
            if (depth == 0) {
                if (sitesettings["children"]) {
                    for (var [key, value] of Object.entries(sitesettings["children"])) {
                        let temp = {};
                        temp[key] = value;
                        temp[key].parent_group = sitesettings.settings;
                        temp[key].path = x;
                        temp[key].short_path = s_path;
                        result.push(temp);
                    }
                    return result;
                    // return {...sitesettings["children"], parent_group: sitesettings.settings, path: x, short_path: s_path};
                }
                return 0;
            }
            if (sitesettings["children"]) {
                Object.values(sitesettings["children"]).forEach((val) => {
                    let y = this.getChildren(val, depth - 1, x, s_path);
                    if (y) result = result.concat(y);
                });
            }
            return result;
        },

        getTree(sitesettings) {
            let tree = [];
            if (sitesettings["children"]) {
                Object.values(sitesettings["children"]).forEach((val) => {
                    val["settings"]["path"] = sitesettings["settings"]["path"] + "/" + val["short_name"];
                    let child = {
                        text: val["settings"]["name"],
                        opened: true,
                        selected: false,
                        path: val["settings"]["path"],
                        children: this.getTree(val),
                    };
                    tree.push(child);
                });
            }
            return tree;
        },

        fetch_groups(afterSuccess) {
            axios
                // .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_organization?recursive`)
                //? mockData
                .get("/GroupOrganization/data")
                .then((res) => {
                    this.group_organization = res.data;

                    let platform_groups = [];
                    this.getChildren(this.group_organization, this.group_levels.length).map((g) => {
                        Object.keys(g)
                            .filter((k) => k != "path" && k != "short_path" && k != "parent_group")
                            .map((gg) => {
                                platform_groups.push({ ...g[gg], parent_group: g[gg].parent_group.name });
                            });
                    });
                    this.platform_groups = this.group_organization.settings.groups.map((gr) => {
                        let g = platform_groups.find((x) => x.settings.platform_group == gr.group);
                        gr.self = g;
                        return gr;
                    });
                    this.teams = this.getChildren(this.group_organization, this.group_levels.length - 1);

                    if (afterSuccess) {
                        afterSuccess(res);
                    }
                    // console.log("group_organization", this.group_organization)
                    // console.log('teams', this.teams)
                    // console.log("platform_groups", this.platform_groups)
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        save_group_levels() {
            axios
                .patch(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_levels`, {
                    setting: this.group_levels,
                })
                .then((res) => {
                    notifySuccess("Successful", "Save successfully!");
                    this.isEditingGroupLevels = false;
                })
                .catch((err) => {
                    this.isEditingGroupLevels = false;
                    notifyError("Error", err?.response?.data?.errors?.message ?? "Save failed");
                });
        },

        async createNewGroupFolder(subfolder = false) {
            if (this.group_folder_item.name == "") return;
            // console.log(subfolder)
            let setting = {
                level: this.groupOrganizationSelectedItem.level + (subfolder ? 1 : 0) + 1,
                name: this.group_folder_item.name,
            };

            let path = "/site_settings/risk_management/" + this.groupOrganizationSelectedItem.path;
            if (!subfolder) {
                path = path.split("/");
                path.pop();
                path = path.join("/");
            }

            try {
                // console.log(path, setting)
                this.isLoading = true;
                this.$bvModal.hide(`modal-new-${subfolder ? "subfolder" : "folder"}`);
                let res = await axios.post(`${process.env.VUE_APP_API_URL}${path}`, {
                    setting,
                    risk_management: 1,
                });
                this.isLoading = false;
                this.fetch_groups();
                notifySuccess("Successful", "Created successfully!");
            } catch (err) {
                console.error(err);
                notifyError("Error", err?.response?.data?.errors?.message ?? "Save failed");
            } finally {
                this.group_folder_item.name = "";
            }
        },

        handleGroupOrganizationAction(action) {
            if (!this.groupOrganizationSelectedItem) {
                this.actionOnRowError = "Please select a folder first before action";
                return;
            }
            // console.log(this.groupOrganizationSelectedItem)
            if (action === "NEW_GROUP_FOLDER") {
                let level = this.groupOrganizationSelectedItem.level + 1;
                if (level > this.group_levels.length) {
                    this.actionOnRowError = "Cannot create new group folder on this level";
                    return;
                }
                this.actionOnRowError = "";
                this.$bvModal.show("modal-new-folder");
            } else if (action === "NEW_SUBFOLDER") {
                let level = this.groupOrganizationSelectedItem.level + 1 + 1;
                if (level > this.group_levels.length) {
                    this.actionOnRowError = "Cannot create new subfolder on this level";
                    return;
                }
                this.actionOnRowError = "";
                this.$bvModal.show("modal-new-subfolder");
            } else if (action === "RENAME") {
                let level = this.groupOrganizationSelectedItem.level + 1;
                if (level > this.group_levels.length) {
                    this.actionOnRowError = "You can only rename a folder";
                    return;
                }
                this.actionOnRowError = "";
                this.$bvModal.show("modal-rename-folder");
            } else if (action === "DELETE_FOLDER") {
                let level = this.groupOrganizationSelectedItem.level + 1;
                if (level > this.group_levels.length) {
                    this.actionOnRowError = "You can only delete folders.";
                    return;
                }
                this.$bvModal.show("modal-delete-folder");
            }
        },

        compare2string(str1, str2) {
            //eaera-xyz Eaera xyz
            return (
                str1 &&
                str2 &&
                str1
                    .split(" ")
                    .join("-")
                    .toLocaleLowerCase() ==
                    str2
                        .split(" ")
                        .join("-")
                        .toLocaleLowerCase()
            );
        },

        calcLevelOpions(index) {
            if (index == 0 && this.group_organization.children) return Object.keys(this.group_organization.children);

            if (index > 0) {
                // Check parent filter
                let checked = true;
                for (let i = 0; i < index; i++) checked &&= this.filter[i];
                var result = [];
                if (checked) {
                    let x = this.getChildren(this.group_organization, index).map((item) => {
                        let key = Object.keys(item)[0];

                        if (this.compare2string(item[key].parent_group.name, this.filter[index - 1])) result.push(key);
                    });
                    return result;
                }
            }
        },
        setGroupOrganizationSelectedRow({ index, item }) {
            this.groupOrganizationSelectedItem = item;
            this.actionOnRowError = false;
        },
        collapseItem({ index, item }) {
            let returnedNode = this.getRegionTreeNodeByPath(item.path);
            returnedNode.opened = !returnedNode.opened;
        },
        getRegionTreeNodeByPath(path, node = null) {
            if (!node) {
                // node = null = highest level
                let res = null;
                this.region_tree.some((node) => {
                    let tmp = this.getRegionTreeNodeByPath(path, node);
                    if (tmp) {
                        res = tmp;
                        return true;
                    } else return false;
                });
                return res;
            }
            if (node.path === path) return node;
            if (!node.children || node.children.length == 0) return null;

            let res = null;
            node.children.some((child) => {
                let tmp = this.getRegionTreeNodeByPath(path, child);
                if (tmp) {
                    res = tmp;
                    return true;
                } else return false;
            });
            return res;
        },
    },

    computed: {
        calc_currency() {
            let groups = this.server_name_options[this.platform_group_item.server_name];

            let selected_group = this.platform_group_item.platform_group;
            if (groups && groups.length && selected_group) {
                return groups.filter((x) => x.platform_group == selected_group)[0].currency;
            }
            return "";
        },

        tree_view: {
            get: function() {
                this.region_tree.splice(0);
                if (this.group_organization.children) {
                    if (!this.group_organization["settings"]) {
                        this.group_organization["settings"] = {};
                    }
                    this.group_organization["settings"]["path"] = "/" + this.group_organization["short_name"];
                    this.region_tree = this.getTree(this.group_organization);
                    // console.log(this.region_tree)
                }
                return this.region_tree;
            },
            set: function(tree) {
                this.region_tree = tree;
            },
        },

        calcParentOptions() {
            if (this.teams.length) {
                return this.teams.map((item) => {
                    return {
                        text: Object.values(item)[0].settings.name,
                        value: Object.values(item)[0].path + "/" + Object.keys(item)[0],
                    };
                });
            }
            return [];
        },

        tree_levels() {
            let result = this.group_levels.map((level) => {
                return {
                    key: level,
                    label: level,
                };
            });
            result.push({
                key: "Group",
                label: "Group",
            });
            return result;
        },
    },
    mounted() {
        this.isLoading = true;
        axios
            // .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/group_levels`)
            // mockData
            .get("/GroupLevel/data")
            .then((res) => {
                this.isLoading = false;
                this.group_levels = res.data.settings;
                this.isFetchingGroupLevels = false;
                this.startEditingGroupLevels();
                this.fetch_groups();
                this.fetch_trading_server();
            })
            .finally(() => {
                this.isLoading = false;
            });

        this.$store.commit("app/SET_DATA_LIVE_TIME", "");
    },
};
</script>
<style scoped>
.width-170 {
    width: 170px;
}

.width-270 {
    width: 270px;
}

.custom-btn {
    padding: 8px 18px;
    margin-bottom: 18px;
}
</style>
