export default [
	{
		label: 'Group Name',
		key: 'name',
		sortField: 'name',
		thClass: 'justify-content-center',
		customTdStyle: (item) => {
			if (item.name && item.parent) return {}
			return {
				'background-color': '#381a1c !important'
			}
		}
	},
	{
		label: 'Parent Group',
		key: 'parent',
		sortField: 'parent',
		thClass: 'justify-content-center',
		customTdStyle: (item) => {
			if (item.name && item.parent) return {}
			return {
				'background-color': '#381a1c !important'
			}
		}
	},
	{
		label: 'Server Name',
		key: 'server',
		thClass: 'justify-content-center',
		sortField: 'server',
		customTdStyle: (item) => {
			if (item.name && item.parent) return {}
			return {
				'background-color': '#381a1c !important'
			}
		}
	},
	{
		label: 'Platform Groups',
		key: 'group',
		thClass: 'justify-content-center',
		sortField: 'group',
		customTdStyle: (item) => {
			if (item.name && item.parent) return {}
			return {
				'background-color': '#381a1c !important'
			}
		}
	},
	{
		label: 'Currency',
		key: 'currency',
		thClass: 'justify-content-center',
		sortField: 'currency',
		customTdStyle: (item) => {
			if (item.name && item.parent) return {}
			return {
				'background-color': '#381a1c !important'
			}
		}
	},
	{
		label: 'Date Created',
		key: 'timestamp',
		thClass: 'justify-content-center',
		sortField: 'timestamp',
		customTdStyle: (item) => {
			if (item.name && item.parent) return {}
			return {
				'background-color': '#381a1c !important'
			}
		}
	},
	{
		label: '',
		key: 'actions',
		thClass: 'justify-content-center',
		customTdStyle: (item) => {
			if (item.name && item.parent) return {}
			return {
				'background-color': '#381a1c !important'
			}
		}
	}
]