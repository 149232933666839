<template>
    <div class="setting-tab">
        <div class="ml-3">
            <!-- Risk Thresholds table -->
            <div>
                <div class="col-7">
                    <h4 class="mb-2 p-0">Default Risk Thresholds</h4>
                    <div class="d-flex p-0 mb-50">
                        <div class="col-9 p-0 d-flex">
                            <div class="col-6 pl-0">
                                <h5 class="m-0 font-weight-normal">Medium Risk Default</h5>
                            </div>
                            <div class="col-6 pr-0">
                                <h5 class="m-0 font-weight-normal">High Risk Default</h5>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-2 pl-0">
                        <div class="col-9 p-0 d-flex">
                            <div class="col-6 pl-0">
                                <!-- <p>Medium Risk Default</p> -->
                                <b-form-input
                                    v-if="isEditingDefault"
                                    type="number"
                                    v-model.number="editingItem.medium"
                                />
                                <b-form-input v-else disabled :value="formatMoney(risk_threshold.default.medium, 2)" />
                            </div>

                            <div class="col-6 pr-0">
                                <!-- <p>High Risk Default</p> -->
                                <b-form-input v-if="isEditingDefault" type="number" v-model.number="editingItem.high" />
                                <b-form-input v-else disabled :value="formatMoney(risk_threshold.default.high, 2)" />
                            </div>
                        </div>

                        <div class="col-3 p-0 d-flex justify-content-end">
                            <div v-if="!isEditingDefault" class="d-flex align-items-end">
                                <font-awesome-icon
                                    :icon="['far', 'edit']"
                                    class="text-success btn-icon font-small-4 mx-1 my-75"
                                    @click="startEditDefault"
                                />
                                <b-button variant="gradient-success" class="" @click="applyAll">
                                    Apply All
                                </b-button>
                            </div>
                            <div v-else class="d-flex align-items-end">
                                <span class="text-success my-75 cursor-pointer" @click="finishEditDefault(true)">
                                    Confirm
                                </span>
                                <span class="text-danger mx-1 my-75 cursor-pointer" @click="finishEditDefault(false)">
                                    Cancel
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="p-0 d-flex justify-content-between align-items-center mb-1">
                        <h4 class="p-0 m-0">Symbols Risk Thresholds</h4>
                        <div class="d-flex justify-content-end align-items-center">
                            <span
                                class="text-danger mr-1 cursor-pointer"
                                v-if="isFilterShown && !isFilterClear"
                                @click="clearFilter"
                            >
                                Clear Search
                            </span>
                            <font-awesome-icon
                                :icon="['far', 'filter']"
                                class="btn-icon mr-1"
                                v-b-tooltip.hover.top="'Filter'"
                                @click="toggleFilter"
                            />
                            <b-button variant="gradient-success" class="" @click="synchronizeSymbols()">
                                Synchronize
                            </b-button>
                        </div>
                    </div>

                    <b-card no-body class="my-shadow rounded px-50 pb-50">
                        <CommonTable
                            :isLoading="isLoading"
                            :fields="fields"
                            :items="itemsAfterFiltered"
                            :has-summary-row="false"
                            :maxDisplayRows="999999"
                            :isFilterShown="isFilterShown"
                            @filterChange="filterChange"
                            @filterClear="filterClear"
                            :field_sort="field_sort"
                            :isDesc="isDesc"
                            @onHeaderClick="(field) => sortBy(field)"
                            :hasHighlight="false"
                            ref="table"
                        >
                            <template #head(action)> </template>
                            <template #cell(medium)="{item}">
                                <b-form-input
                                    v-if="isEditingCustom == item.symbol"
                                    type="number"
                                    v-model.number="editingItem.medium"
                                    size="sm"
                                />
                                <b-form-input v-else disabled :value="formatMoney(item.medium, 2)" size="sm" />
                            </template>
                            <template #cell(high)="{item}">
                                <b-form-input
                                    v-if="isEditingCustom == item.symbol"
                                    type="number"
                                    v-model.number="editingItem.high"
                                    size="sm"
                                />
                                <b-form-input v-else disabled :value="formatMoney(item.high, 2)" size="sm" />
                            </template>
                            <template #cell(action)="{item}">
                                <div
                                    v-if="!(isEditingCustom == item.symbol)"
                                    class="d-flex justify-content-center px-4"
                                >
                                    <font-awesome-icon
                                        :icon="['far', 'edit']"
                                        class="text-success btn-icon font-small-4"
                                        @click="startEditCustom(item)"
                                    />
                                </div>
                                <div v-else class="d-flex justify-content-end">
                                    <span class="text-success cursor-pointer" @click="finishEditCustom(item, true)">
                                        Confirm
                                    </span>
                                    <span
                                        class="text-danger mx-1 cursor-pointer"
                                        @click="finishEditCustom(item, false)"
                                    >
                                        Cancel
                                    </span>
                                </div>
                            </template>
                        </CommonTable>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import axios from "@axios";
import Vue from "vue";
import CommonTable from "@/components/tables/CommonTable.vue";

// validation
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { formatMoney, setFilterInLocalStorage, getFilterInLocalStorage, removeFilterInLocalStorage } from "@/helper";
import { notifyError, notifySuccess } from "@/helper/notifications";

const TABLE_NAME = "RiskThreshold";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        vSelect,
        ToastificationContent,
        CommonTable,
    },
    data() {
        return {
            isLoading: false,
            risk_threshold: { default: {}, custom: {} },
            required,
            editingItem: {
                medium: 0,
                high: 0,
            },
            isEditingDefault: false,
            isEditingCustom: null,
            symbols: [],

            maxDisplayRowCount: 19,
            isFilterShown: localStorage.getItem(TABLE_NAME + "Filter") !== null,
            isFilterClear: true,
            filterValues: {},
            field_sort: "symbol",
            isDesc: true,
        };
    },

    computed: {
        fields() {
            return [
                {
                    key: "symbol",
                    sortField: "symbol",
                    label: "Symbol",
                    filter: {
                        type: "input",
                        default: "",
                        placeholder: "All",
                    },
                    thClass: "justify-content-center",
                },
                { key: "medium", sortField: "medium", label: "Medium Risk", thClass: "justify-content-center" },
                { key: "high", sortField: "high", label: "High Risk", thClass: "justify-content-center" },
                { key: "action", label: "" },
            ];
        },

        itemsAfterFiltered() {
            let results = [...this.itemsAfterSorted];
            Object.keys(this.filterValues).forEach((key) => {
                let field = this.fields.find((field) => field.key == key);
                if (field?.filter?.filterFunc) {
                    // console.log(results, this.filterValues[key])
                    results = field.filter.filterFunc(results, this.filterValues[key]);
                } else {
                    if (this.filterValues[key]) {
                        results = results.filter((item) => {
                            return (item[key] ?? "")
                                .toString()
                                .toLowerCase()
                                .includes(this.filterValues[key].toString().toLowerCase());
                        });
                    }
                }
            });
            return results;
        },

        itemsAfterSorted() {
            let results = [...this.items];
            let key = this.fields.find((x) => x.sortField == this.field_sort).key;
            results = results.sort((a, b) => {
                let x = a[key].toString().toLowerCase();
                let y = b[key].toString().toLowerCase();
                if (this.isDesc ? x > y : x < y) return -1;
                return this.isDesc ? x < y : x > y;
            });
            return results;
        },

        items() {
            return Object.entries(this.risk_threshold.custom)
                .map(([key, val]) => ({ symbol: key, ...val }))
                .sort((a, b) => {
                    if (a.symbol < b.symbol) return -1;
                    return a.symbol > b.symbol;
                });
        },
    },

    methods: {
        formatMoney,
        initFilter() {
            if (!localStorage.getItem(TABLE_NAME + "Filter")) return;
            // console.log(TABLE_NAME, JSON.parse(localStorage.getItem(TABLE_NAME + 'Filter')))
            let filterValues = JSON.parse(localStorage.getItem(TABLE_NAME + "Filter"));
            if (Object.values(filterValues).some((value) => value != "")) this.isFilterClear = false;
            this.$refs.table.initFilter(filterValues);
        },

        toggleFilter() {
            this.isFilterShown = !this.isFilterShown;
            if (this.isFilterShown) setFilterInLocalStorage(TABLE_NAME, {});
            else removeFilterInLocalStorage(TABLE_NAME);
        },

        filterChange(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                if (filterValues[key] != "") this.isFilterClear = false;
                this.$set(this.filterValues, key, filterValues[key]);
            });
            setFilterInLocalStorage(TABLE_NAME, filterValues);
        },

        clearFilter() {
            this.$refs.table.clearFilter();
        },

        filterClear(filterValues) {
            Object.keys(filterValues).forEach((key) => {
                this.$set(this.filterValues, key, filterValues[key]);
            });
            this.isFilterClear = true;
            setFilterInLocalStorage(TABLE_NAME, {});
        },

        sortBy(field) {
            // console.log("Sort: ", field)
            if (field) {
                this.field_sort = field;
                this.isDesc = !this.isDesc;
            }
        },

        startEditDefault() {
            this.isEditingDefault = true;
            this.editingItem = { ...this.risk_threshold.default };
        },

        async finishEditDefault(doSave) {
            this.isEditingDefault = false;
            if (doSave && !isNaN(this.editingItem.medium) && !isNaN(this.editingItem.high)) {
                this.risk_threshold.default.medium = this.editingItem.medium;
                this.risk_threshold.default.high = this.editingItem.high;
                this.save();
            }
        },

        startEditCustom(item) {
            this.isEditingCustom = item.symbol;
            this.editingItem = {
                ...item,
            };
        },

        async finishEditCustom(item, doSave) {
            this.isEditingCustom = null;
            if (doSave && !isNaN(this.editingItem.medium) && !isNaN(this.editingItem.high)) {
                this.$set(this.risk_threshold.custom[item.symbol], "medium", this.editingItem.medium);
                this.$set(this.risk_threshold.custom[item.symbol], "high", this.editingItem.high);
                this.save();
            }
        },

        async applyAll() {
            this.symbols.forEach((symbol) => {
                this.risk_threshold.custom[symbol] = { ...this.risk_threshold.default };
            });
            await this.save();
        },

        async save() {
            await axios
                .patch(`${process.env.VUE_APP_API_URL}site_settings/risk_management/risk_threshold`, {
                    setting: this.risk_threshold,
                    overwrite: true,
                })
                .then((res) => {
                    this.editing_item = null;
                    notifySuccess("Successful", "Save successfully!");
                })
                .catch((err) => {
                    console.error(err);
                    notifyError("Error", err?.response?.data?.errors?.message ?? "Save failed");
                });
        },

        async synchronizeSymbols() {
            await axios
                .post(`${process.env.VUE_APP_API_URL}mt_symbols/sync_symbols`)
                .then(async (res) => {
                    this.symbols = res.data.map((x) => x.Symbol);
                    notifySuccess("Successful", "Synchronize successfully!");
                })
                .catch((err) => {
                    console.error(err);
                    notifyError("Error", err?.response?.data?.errors?.message ?? "Synchronize failed");
                });
        },

        async fetchRiskThreshold() {
            await axios
                .get(`${process.env.VUE_APP_API_URL}site_settings/risk_management/risk_threshold`)
                .then((res) => {
                    this.risk_threshold = res.data.settings;
                    // notifySuccess('Successful', 'Fetch thresholds successfully!')
                })
                .catch((err) => {
                    console.error(err);
                    notifyError("Error", err?.response?.data?.errors?.message ?? "Fetch thresholds failed");
                });
        },

        async fetchSymbols() {
            await axios
                .get(`${process.env.VUE_APP_API_URL}mt_symbols`)
                .then((res) => {
                    this.symbols = res.data.map((x) => x.symbol);
                    // notifySuccess('Successful', 'Fetch symbols successfully!')
                })
                .catch((err) => {
                    console.error(err);
                    notifyError("Error", err?.response?.data?.errors?.message ?? "Fetch symbols failed");
                });
        },
    },

    watch: {
        symbols() {
            // console.log("watch: symbols")
            let doSave = false;
            this.symbols.forEach((symbol) => {
                if (!this.risk_threshold.custom[symbol]) {
                    doSave = true;
                    // console.log("Add symbol: ", symbol);
                    this.risk_threshold.custom[symbol] = {
                        medium: this.risk_threshold.default.medium,
                        high: this.risk_threshold.default.high,
                    };
                }
            });

            Object.keys(this.risk_threshold.custom).forEach((symbol) => {
                if (!this.symbols.includes(symbol)) {
                    doSave = true;
                    // console.log("Delete symbol: ", symbol);
                    delete this.risk_threshold.custom[symbol];
                }
            });

            if (doSave) {
                this.save();
            }
        },
    },

    channels: {
        SymbolConfigChannel: {
            connected() {
                console.log("Connected to the SymbolConfigChannel");
            },
            rejected() {
                console.log("Rejected to the SymbolConfigChannel");
            },
            received(event) {
                // console.log(event)
                if (event.name == "trade.MtConfig.SymbolConfigSync") {
                    this.symbols = event.payload.context.map((x) => x.symbol);
                }
            },
            disconnected() {
                console.log("Disconnected to the SymbolConfigChannel");
            },
        },
    },

    async mounted() {
        //? mockData
        // this.$cable.subscribe({
        //   channel: 'SymbolConfigChannel'
        // });

        try {
            this.isLoading = true;
            await this.fetchRiskThreshold();
            await this.fetchSymbols();
            this.initFilter();
        } catch (err) {
            console.error(err);
        } finally {
            this.isLoading = false;
        }
        this.$store.commit("app/SET_DATA_LIVE_TIME", "");
    },
};
</script>

<style scoped></style>
