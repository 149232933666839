var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-end w-100",staticStyle:{"height":"0px"}},[_c('b-button',{staticClass:"custom-sync-btn",attrs:{"variant":"gradient-info"},on:{"click":function($event){return _vm.$emit('sync-group')}}},[_vm._v(" Synchronization ")])],1),_c('b-card',{staticClass:"my-shadow px-50 pb-50",attrs:{"no-body":""}},[_c('CommonTable',{attrs:{"isLoading":_vm.isLoading,"fields":_vm.fields,"is-header-small":true,"items":_vm.items,"max-display-rows":999999,"has-summary-row":false,"isFilterShown":false,"hasHighlight":false,"field_sort":_vm.field_sort,"isDesc":_vm.isDesc},on:{"onHeaderClick":function (field) { return _vm.sortBy(field); }},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(!_vm.isEditing(item))?_c('b-form-input',{staticClass:"platform-group__input",attrs:{"disabled":"","value":item.name,"size":"sm"}}):_c('b-form-input',{staticClass:"platform-group__input",attrs:{"size":"sm"},model:{value:(_vm.editItem.name),callback:function ($$v) {_vm.$set(_vm.editItem, "name", $$v)},expression:"editItem.name"}})]}},{key:"cell(parent)",fn:function(ref){
var item = ref.item;
return [(!_vm.isEditing(item))?_c('b-form-input',{staticClass:"platform-group__input",attrs:{"disabled":"","value":_vm.getParentOptionText(item),"size":"sm"}}):_c('b-form-select',{staticClass:"platform-group__input",attrs:{"options":_vm.parentOptions,"size":"sm"},model:{value:(_vm.editItem.parent),callback:function ($$v) {_vm.$set(_vm.editItem, "parent", $$v)},expression:"editItem.parent"}})]}},{key:"cell(timestamp)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.timestamp).format("YYYY-MM-DD HH:mm:ss"))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(!_vm.editingGroup)?_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('font-awesome-icon',{staticClass:"text-success btn-icon font-medium-1",attrs:{"icon":['far', 'edit']},on:{"click":function($event){return _vm.openEditGroup(item)}}}),_c('b-form-checkbox',{attrs:{"disabled":!_vm.validateGroup(item),"switch":""},on:{"change":function($event){return _vm.toggleGroupState(item)}},model:{value:(item.enable),callback:function ($$v) {_vm.$set(item, "enable", $$v)},expression:"item.enable"}})],1):(_vm.isEditing(item))?_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"text-success",on:{"click":function($event){return _vm.confirmEditGroup(item)}}},[_vm._v("Confirm")]),_c('span',{staticClass:"text-danger",on:{"click":function($event){return _vm.closeEditGroup(item)}}},[_vm._v("Cancel")])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }