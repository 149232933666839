<template>
  <!-- Group Levels -->
  <div class="mb-2">
    <h4 class="mb-1 font-small-3">
      Group Levels
    </h4>
    <b-spinner small v-if="isFetchingGroupLevels"></b-spinner>
    <div class="d-flex" v-if="(!isFetchingGroupLevels && group_levels && group_levels.length > 0)">
      <div class="text-left mr-2" v-for="(item, index) in group_levels" :key="index">
        <h5 class="mb-50 mr-2 group-levels">Level {{index+1}}</h5>
        <div class="d-flex align-items-center"> 
          <b-form-input
            disabled
            class="my-shadow group-levels-input" 
            v-model="group_levels[index]"
            v-if="!isEditingGroupLevel[index]"
          />
          <b-form-input 
            class="my-shadow group-levels-input"
            v-model="groupLevelsOnEdit[index]"
            v-else
          />
          <font-awesome-icon 
            :icon="['far', 'edit']" 
            class="text-success font-medium-3 btn-icon ml-75" 
            v-if="!isEditingGroupLevel[index]" 
            @click="$emit('editGroupLevel', index)"
          />
          <font-awesome-icon 
            :icon="['far', 'check']" 
            class="text-success font-medium-3 btn-icon ml-75" 
            v-else
            @click="$emit('renameGroupLevel', index, groupLevelsOnEdit[index])"
          />
        </div>
      </div>
      <b-button variant="success" size="sm" class=" align-self-end" style="height: 35px" @click="$emit('addGroupLevel')">+ New Level</b-button>
      <b-button variant="danger" size="sm" class="ml-2 align-self-end" style="height: 35px" @click="$emit('deleteLastGroupLevel')">- Delete Last Level</b-button>
      <!-- <span v-else>
        <font-awesome-icon 
          :icon="['far', 'plus']" 
          class="text-success btn-icon my-auto mr-1" 
          @click="addGroupLevel"
        />
        <feather-icon icon="SaveIcon" size="18" class="text-info btn-icon mt-4 mr-1" @click="save_group_levels()"/>
        <feather-icon icon="LogOutIcon" size="18" class="text-warning btn-icon mt-4" @click="isEditingGroupLevels = false"/>
      </span> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["isFetchingGroupLevels", "group_levels", "isEditingGroupLevel"],

  data() {
    return {
      groupLevelsOnEdit: [...this.group_levels],
    }
  },
  
  watch: {
    group_levels() {
      this.groupLevelsOnEdit = [...this.group_levels]
    }
  }
}
</script>
<style scoped>
 .group-levels {
    font-size: 12px;
  }
  .group-levels-input{
    border-radius: 0.358rem !important; 
    width: 120px
  }
</style>