<template>
  <!-- Platform Groups Table -->
  <div>
    <div class="d-flex justify-content-end w-100" style="height: 0px">
        <b-button variant="gradient-info" class="custom-sync-btn" @click="$emit('sync-group')">
          Synchronization
        </b-button>
    </div>

    <b-card no-body class="my-shadow px-50 pb-50">
      <CommonTable
        :isLoading="isLoading"
        :fields="fields"
        :is-header-small="true"
        :items="items"
        :max-display-rows="999999"
        :has-summary-row="false"
        :isFilterShown="false"
        :hasHighlight="false"
        :field_sort="field_sort"
        :isDesc="isDesc"
        @onHeaderClick="field => sortBy(field)"
      >
        <template #cell(name)="{item}">
          <b-form-input 
            disabled
            v-if="!isEditing(item)"
            :value="item.name"
            size="sm"
            class="platform-group__input"
          />
          <b-form-input 
            v-else 
            v-model="editItem.name"
            size="sm"
            class="platform-group__input"
          />
        </template>
        <template #cell(parent)="{item}">
          <b-form-input 
            disabled
            v-if="!isEditing(item)"
            :value="getParentOptionText(item)"
            size="sm"
            class="platform-group__input"
          />
          <b-form-select v-else 
            v-model="editItem.parent" 
            :options="parentOptions"
            size="sm"
            class="platform-group__input"
          />
        </template>
        <template #cell(timestamp)="{ item }">
          {{moment(item.timestamp).format("YYYY-MM-DD HH:mm:ss")}}
        </template>
        <template #cell(actions)="{ item }">
          <div v-if="!editingGroup" class="d-flex justify-content-between align-items-center">
            <font-awesome-icon :icon="['far', 'edit']" class="text-success btn-icon font-medium-1" @click="openEditGroup(item)"/>
            <b-form-checkbox :disabled="!validateGroup(item)" v-model="item.enable" switch @change="toggleGroupState(item)"/>
          </div>
          <div v-else-if="isEditing(item)" class="d-flex justify-content-between">
            <span class="text-success" @click="confirmEditGroup(item)">Confirm</span>
            <span class="text-danger" @click="closeEditGroup(item)">Cancel</span>
          </div>
        </template>
      </CommonTable>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from "vue-select";
import axios from '@axios'
import VueSelect from '../forms/form-element/vue-select/VueSelect.vue'
import CommonTable from "@/components/tables/CommonTable.vue"
import { required} from '@validations'
import groupManagementFields from './groupManagementFields.js'
import { notifyError, notifySuccess } from '@/helper/notifications'
import moment from "moment"

export default {
  components: {
    ToastificationContent,
    CommonTable
  },

  props: {
    group_levels: Array,
    group_organization: Object,
    parentOptions: Array,
  },
  
  data() {
    return {
      required,
      // add, edit platform Group
      isLoading: false,
      editingGroup: null,
      editItem: null,
      current_item: null,
      field_sort: "name",
      isDesc: true,
    }
  },

  computed: {
    fields() {
      let results = groupManagementFields
      // results.forEach(field => {
      //   if (!field.tdStyle) field.tdStyle = {}
      //   field.tdStyle = {...field.tdStyle, 'border': '1px solid red!important'}
      // })
      return groupManagementFields
    },

    items() {
      let results = this.platform_groups
      let key = this.fields.find(x => x.sortField == this.field_sort).key
      results = results.sort((a, b) => {
        let x = a[key].toString().toLowerCase()
        let y = b[key].toString().toLowerCase()
        if (this.isDesc ? (x > y) : (x < y)) return -1
        return (this.isDesc ? (x < y) : (x > y))
      })
      return results
    },

    platform_groups: {
      get() {
        return this.group_organization?.settings?.["groups"] ?? []
      },
      set(groups) {
        let tmp = {...this.group_organization}
        tmp.settings["groups"] = groups
        this.$emit("setGroupOrganization", tmp)
      }
    },
  },


  mounted() {
  },

  methods: {
    moment,

    sortBy(field){
      // console.log("Sort: ", field)
      if(field){
        this.field_sort = field
        this.isDesc = !this.isDesc
      }
    },

    getParentOptionText(item) {
      return this.parentOptions?.find(x => x.value == item.parent)?.text
    },

    openEditGroup(item) {
      this.editingGroup = item
      this.editItem = {...item}
    },

    closeEditGroup(item) {
      this.editingGroup = null
      this.editItem = null
    },

    async confirmEditGroup(item) {
      this.isLoading = true
      try {
        item = {
          ...item,
          name: this.editItem.name,
          parent: this.editItem.parent,
        }
        await this.editRiskGroup(item, item.enable)
        await this.closeEditGroup(item)
        notifySuccess('Successfully', 'Edit group successfully!')
        this.fetch_groups()
      } catch (err) {
        notifyError('Error', err?.response?.data?.errors?.message ?? 'Save failed')
      } finally {
        this.isLoading = false
      }
    },

    isEditing(item) {
      return item.server == this.editingGroup?.server && item.group == this.editingGroup?.group
    },

    validateGroup(item) {
      return item.name && item.parent
    },

    async toggleGroupState(item) {
      // console.log(item, item.enable)
      // this.isLoading = true
      try {
        if (item.enable && this.validateGroup(item)) {
          let res = await this.enableGroup(item)
          item.self = res.data
        } else {
          await this.disableGroup(item)
          item.self = undefined
        }
        
        await this.editRiskGroup(item, false)
        notifySuccess('Successfully', 'Change group state successfully!')
        this.fetch_groups()
      } catch (err) {
        notifyError('Error', err?.response?.data?.errors?.message ?? 'Save failed')
      } finally {
        // this.isLoading = false
      }
    },

    async enableGroup(item) {
      if (!item.parent) {
        item.enable = false
        throw (`enableGroup error, item = ${item}`)
      }
      let platform_group_item = {
        "name": item.name,
        "path": item.parent,
        "currency": item.currency,
        "server_name": item.server,
        "platform_group": item.group,
        "short_path": item.parent?.slice?.(20),
        "created": (new Date()).toLocaleDateString("en-US"),
        level: this.group_levels.length + 1,  
      }

      return await axios.post(`${process.env.VUE_APP_API_URL}site_settings/risk_management${item.parent}`, {
        setting: platform_group_item, 
        risk_management: 1,
        update_metric_properties: 1
      })
    },

    async disableGroup(item){
      if (!item?.parent || !item?.name) {
        throw ("disableGroup error, item = " + item)
      }
      return await axios.delete(`${process.env.VUE_APP_API_URL}site_settings/destroy_node/risk_management/group_organization/${item.path}`,
      {
        params: {
          delete_relate_metric_data: 1,
          pass: 1
        }
      })
    },
    
    async editRiskGroup(item, isEnable=true){
      if (isEnable) {
        // await this.disableGroup(item)
      }
      return await this.updateGroup(item)
      if (isEnable) {
        // await this.enableGroup(item)
      }
    },

    async updateGroup(item) {
      let origin_path_onEdit = item.self?.path ?? item.parent
      let setting = {
        "name": item.name,
        "path": item.parent,
        "short_path": item.parent?.slice?.(20),
        "currency": item.currency,
        "server_name": item.server,
        "platform_group": item.group,
        "created": item.self?.settings?.created,
        level: this.group_levels.length + 1,  
      }
      // console.log(item.self?.short_name)
      await axios.patch(`${process.env.VUE_APP_API_URL}site_settings/update_risk_group/risk_management${item.enable ? `${origin_path_onEdit}/${item.self?.short_name}` : ""}`, {
        group: item,
        setting: setting
      })
    },

    fetch_groups(afterSuccess){
      this.$emit("fetch_groups", afterSuccess)
    },
  },
}
</script>

<style scoped>
  .group-levels-input{
    border-radius: 20px; 
    width: 120px
  }
  .width-170 {
    width: 170px
  }

  .width-270 {
    width: 270px
  }

  .custom-sync-btn {
    position: absolute;
    top: 0px;
    padding: 8px 18px;
  }
</style>
<style>
.platform-group__input {
  height: 25px !important;
}
</style>