<template>
	<div v-if="isLoading" class="w-100 py-5 text-center" 
		style="border: none !important" 
	>
		<b-spinner variant="primary"></b-spinner>
	</div>
	<div v-else :class="`common-table table-${maxDisplayRows}-rows ${overflowClass} w-100 table-header-sm`">
		<div class="table-outer-1 w-100" ref="table-outer-1">
			<div class="table-outer-2 w-100"
				:style="{
					paddingRight: (tableItems.length > maxDisplayRows) ? '0px' : '0px',
					marginRight: (tableItems.length > maxDisplayRows) ? '-7px' : '0px'
				}"
				ref="table-outer-2" 
				@scroll="scrollContentHandler"
			>
					<b-table-simple
						:small="small"
						:hover="hover"
						:no-border-collapse="noBorderCollapse"
						borderless
						:striped="striped"
						class="w-100"
						fixed
					>
						<b-thead :style="{
							'overflow-y': tableItems.length + 1 < maxDisplayRows ? 'auto !important' : 'scroll !important'
						}">
							<b-tr ref="header-1st-row">
								<slot name="header">
									<b-th
										v-for="(field, index) in computedFields"
										:key="index"
										:class="field.sortField ? 'cursor-pointer field.thClass' : 'field.thClass'"
										:style="{
											'padding': '1rem',	// 100px is to large for some column
											...field.thStyle
										}"
										class="font-weight-bolder"
										@click="sortField(field)"
									>
										<slot :name="`head(${field.key})`" :field="field" :value="field.label">
											{{ field.label }}
											<!-- <feather-icon v-if="field_sort == field.sortField" :icon="isDesc ? 'ChevronDownIcon' : 'ChevronUpIcon'"/> -->
										</slot>
									</b-th>
								</slot>
							</b-tr>
						</b-thead>
						<!-- <div class="body-wrapper"
							:style="{
								'overflow-y': items.length + 1 < maxDisplayRows ? 'auto !important' : 'scroll !important'
							}"
						> -->
						<b-tbody>
							<b-tr
								v-for="item, index in tableItems"
								:key="index"
							>
								<b-td 
									v-for="field, jj in computedFields"
									:key="jj"
									class="py-0"
									:class="{ 'row-selected': selectedRow === index }"
									:ref="`cell-${index}-${jj}`"
									@click="selectRow(index)"
								>
									<span v-if="jj == item.level" >
										<font-awesome-icon :icon="['fad', 'folder-open']" v-if="jj < computedFields.length - 1" class="mr-50" />
										<font-awesome-icon :icon="['fal', 'layer-group']" v-else class="mr-50" />
										<span>
											{{ item.text }}	
										</span>
										<font-awesome-icon :icon="['fas', 'caret-down']" v-if="jj < computedFields.length - 1 && item.opened" class="btn-icon"  @click="collapseItem(index)" />
										<font-awesome-icon :icon="['fas', 'caret-right']" v-if="jj < computedFields.length - 1 && !item.opened"  class="btn-icon" @click="collapseItem(index)" />
									</span>
									<div v-if="jj <= item.level - 1" class="tree-branch">
										<!-- {{ item.isLastChild[item.level - jj - 1]}} -->
										<div class="horizontal-line" v-if="jj == item.level - 1"></div>
										<div 
											class="vertical-line" 
											:class="{ 
												'full': !item.isLastChild[item.level - jj - 1], 
												'half': item.isLastChild[item.level - jj - 1], 
												'none': item.isLastChild[item.level - jj - 1] && index > 0 && tableItems[index - 1].isLastChild[tableItems[index - 1].level - jj - 1], 
											}"
										></div>
									</div>
								</b-td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
			</div>
		</div>
	
		<!-- Use an anchor -->
		<div ref="anchor" style="left: 0; position: absolute; top: 0"></div>

		<!-- Custom scrollbar -->
		<div ref="scrollbar-anchor" class="scrollbar-anchor"
			v-if="isMounted  && !isLoading && tableItems.length > maxDisplayRows"
			:style="{
				top: 0,
				right: '0px',
				height: `${tableOuterHeight}px`
			}"
		>
			<div ref="track" class="track"
				:style="{
					height: trackHeight + 'px',
					top: tableHeaderHeight + 'px'
				}"
			>
				<div ref="thumb" class="thumb"
					:style="{
						height: thumbHeight + 'px',
						top: thumbTopPosition + 'px'
					}"
					@mousedown="mouseDownThumbHandler"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
import { formatMoney } from '@/helper'
import variables from '@/assets/scss/variables/_variables-components.scss'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
	props: {
		field_sort:{
			type: String,
			default: ''
		},
		isDesc: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: true
		},
		hover: {
			type: Boolean,
			default: true
		},
		noBorderCollapse: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},

		striped: {
			type: Boolean,
			default: true
		},
		maxDisplayRows: {
			type: Number,
			default: 999999
		},
		hasFooter: {
			type: Boolean,
			default: false
		},
    isLoading: {
      type: Boolean,
      default: false,
    },
		levels: {
			type: Array,
			default: () => []
		},
		items: {
      type: Array,
      default() {
        return []
      }
    }
	},
	data() {
		return {
			isMounted: false,
			tableRowHeight: parseInt(variables.tableRowHeight),
			tableHeaderOffsetHeight: 0,
			pos: { top: 0, y: 0 },
			isMouseDown: false,
			thumbTopPosition: 0,
			tableItems: [],
			selectedRow: -1	
		}
	},
	methods: {
		formatMoney,
		handleHeaderHeight() {
			let firstRow = this.$refs['header-1st-row'].$el
			let firstRowHeight = firstRow.offsetHeight
			this.tableHeaderOffsetHeight = firstRowHeight
			this.$refs['table-outer-1'].style.maxHeight = this.tableOuterHeight + 'px'
			this.$refs['table-outer-2'].style.maxHeight = this.tableOuterHeight + 'px'
		},
		selectRow(index) {
			this.selectedRow = index
			this.$emit('row-selected', { index, item: this.tableItems[index] })
		},
		mouseDownThumbHandler(e) {
			this.isMouseDown = true
			this.pos = {
				// The current scroll 
				top: this.$refs['table-outer-2'].scrollTop,
				// Get the current mouse position
				y: e.clientY,
			}
			document.addEventListener('mousemove', this.mouseMoveHandler)
			document.addEventListener('mouseup', () => {
				this.isMouseDown = false
			})
		},
		mouseMoveHandler(e) {
			if (!this.isMouseDown) return
			// How far the mouse has been moved
			const dy = e.clientY - this.pos.y;
			this.$refs['table-outer-2'].scrollTop = this.pos.top + dy / this.scrollRatio;
		},

		scrollContentHandler(e) {
			this.$nextTick(() => {
				this.thumbTopPosition = (this.trackHeight - this.thumbHeight) * (e.target.scrollTop / (e.target.scrollHeight - this.tableOuterHeight))
			})
		},

		dfs(level, item, isLastChild = [ false ]) {
			// isLastChild: array to check if the node is last child of its parent or not, isLastChild[0] checks the node itself, isLastChild[1] checks the parent node, isLastChild[2] checks the grandparent node
			let result = []
			result.push({
				...JSON.parse(JSON.stringify(item)),
				level,
				isLastChild,
			})
			if (item.opened) {
				item.children.forEach((child, index) => {
					if (index === item.children.length - 1)
						result.push(...this.dfs(level + 1, child, [ true, ...isLastChild] ))
					else
						result.push(...this.dfs(level + 1, child, [ false, ...isLastChild] ))
				})
			}
			result[0].size = result.length
			return result
		},

		collapseItem(index) {
			let item = this.tableItems[index]
			this.$emit('collapse', { index, item })
			this.makeTableItems()
		},

		makeTableItems() {
			this.tableItems = []
			this.items.forEach(item => {
				this.tableItems.push(...this.dfs(0, item))
			})
		}		
	},
	computed: {
    computedFields() {
			// console.log('tree field', this.levels)
      let tmp = [...this.levels]  // shallow copy: avoid multiple push and retain functions (if any) in fields
      return tmp
    },

		tableHeaderHeight() {
			return this.tableHeaderOffsetHeight
		},
		scrollRatio() {
			return this.maxDisplayRows / this.tableItems.length
		},
		trackHeight() {
			return this.maxDisplayRows * this.tableRowHeight
		},
		thumbHeight() {
			return this.scrollRatio * this.trackHeight
		},
		overflowClass() {
			if (this.tableItems.length > this.maxDisplayRows) return 'table-overflow-y'
			return ''
		},
	
		tableOuterHeight() {
			return this.tableHeaderHeight + this.maxDisplayRows * this.tableRowHeight
		}
	},
	watch: {
		items() {
			this.makeTableItems()
		}
	},
	mounted() {
		this.isMounted = true
		this.handleHeaderHeight()
		this.makeTableItems()
	},
	destroy() {
		this.isMounted = false
	}
}
</script>

<style scoped lang="scss">
.tree-branch {
	position: relative;
	width: 100%;
	height: 100%;
	display: block;
	.horizontal-line {
		position: absolute;
		top: 50%;
		display: block;
		width: calc(100% - 0.75rem);
		right: 0;
		height: 1px;
		background: #E4E6EB;
	}
	.vertical-line {
		position: absolute;
		width: 1px;
		background: #E4E6EB;
		top: 0;
		left: 0.75rem;
		&.full {
			height: 100%;
		}
		&.half {
			height: 50%;
		}
		&.none {
			height: 0;
		}
	}
}
.row-selected {
  background-color: rgba(49, 49, 51, 1) !important;
}
</style>